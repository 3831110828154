import { Link, LinkWithIcon } from "@pmi/ui-react";
import { FunctionComponent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group";
import { urls } from "../../App";
import { LoginContext } from "../../contexts/login";
import { PageContext } from "../../contexts/page";
import { Language } from "../../i18n";
import { ReactComponent as Arrow } from "../../svg/arrow.svg";
import { ReactComponent as Exit } from "../../svg/exit.svg";
import logo from "../../svg/logo.svg";
import Dialog, { DialogButtons, YesNoDialogResult } from "../Dialog";
import styles from "./styles.module.scss";


const Menu: FunctionComponent = () => {
  const [t, i18n] = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const login = useContext(LoginContext);
  const page = useContext(PageContext);

  return (
    <header className={`${styles.menu}`}>
      <div className={styles.firstRow}>
        <div className={`${styles.sidePanel} ${styles.back}`}>
          <TransitionGroup>
            {page.onBack && (
              <CSSTransition
                key={"back"}
                timeout={250}
                classNames="menu-fade"
              >
                <LinkWithIcon
                  to=""
                  icon={<Arrow className="reversed" />}
                  onClick={(e) => {
                    e.preventDefault();

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    page.onBack();
                  }}
                >
                  {t("back")}
                </LinkWithIcon>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>

        <Link
          className={styles.logo}
          to={urls.home}
          onClick={(e) => {
            if (location.pathname === urls.home) {
              e.preventDefault();
            }
            if (page.onFlow) {
              e.preventDefault();

              page.showModal(
                <Dialog
                  title={t("Menu.Home.title")}
                  text={t("Menu.Home.text")}
                  dialogButtons={DialogButtons.YesNo}
                  onDialogResult={(result) => {
                    if (result == YesNoDialogResult.No) {
                      page.closeModal();
                    }
                    else if (result == YesNoDialogResult.Yes) {
                      if (page.onHome !== undefined) {
                        page.onHome();
                      }
                      else {
                        navigate(urls.home);
                      }
                      page.closeModal();
                    }
                  }}
                />
              );
            }
            else if (page.onHome !== undefined) {
              page.onHome();
            }
          }}
        >
          <img src={logo} alt="IQOS" />
        </Link>

        <div className={`${styles.sidePanel} ${styles.languages}`}>
          {Object.values(Language).map((language) => (
            <input
              key={language}
              className={`${styles.language} ${styles[language]}`}
              type="radio"
              name="language"
              value={language}
              checked={i18n.language === language}
              onChange={() => {
                i18n.changeLanguage(language);
              }}
            />
          ))}

          {login.authenticated && !page.onFlow && (
            <LinkWithIcon
              className={styles.logout}
              to=""
              icon={<Exit />}
              onClick={(e) => {
                e.preventDefault();

                page.showModal(
                  <Dialog
                    title={t("Menu.Logout.title")}
                    text={t("Menu.Logout.text")}
                    dialogButtons={DialogButtons.YesNo}
                    onDialogResult={(result) => {
                      if (result === YesNoDialogResult.Yes) {
                        login.logout();
                        page.closeModal();
                      }
                      else if (result === YesNoDialogResult.No) {
                        page.closeModal();
                      }
                    }}
                  />
                );
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.secondRow}>
        <SwitchTransition>
          <CSSTransition
            key={page.title}
            timeout={500}
            classNames="menu-title-fade"
          >
            <span className={styles.title}>{page.title}</span>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </header>
  );
};

export default Menu;