import { FlexContainer } from "@pmi/ui-react";
import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

export enum PreloaderState {
  Hidden,
  Visible,
  NetworkError,
}

export type WithPreloaderProps = PropsWithChildren<{
  preloaderState: PreloaderState,
}>;

const WithPreloader: FunctionComponent<WithPreloaderProps> = ({ children, preloaderState }: WithPreloaderProps) => {
  const [t] = useTranslation();

  return (
    <div className={styles.withPreloader}>
      {children}

      <FlexContainer className={`${styles.preloader} ${preloaderState === PreloaderState.Hidden ? styles.hidden : ""}`} gap={12}>
        {preloaderState === PreloaderState.NetworkError && (
          <span>{t("Error.networkError")}</span>
        )}
      </FlexContainer>
    </div>
  );
};

export default WithPreloader; 