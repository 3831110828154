import { FlexContainer, HorizontalAlign, VerticalAlign } from "@pmi/ui-react";
import { FunctionComponent, PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export type ModalProps = PropsWithChildren<Record<string, unknown>>;

const Modal: FunctionComponent<ModalProps> = ({ children }: ModalProps) => {

  return (
    <FlexContainer className={styles.modal} horizontalAlign={HorizontalAlign.Center} verticalAlign={VerticalAlign.Middle}>
      <FlexContainer className={styles.window}>
        <FlexContainer className={styles.content} verticalAlign={VerticalAlign.Top}>
          {children}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Modal;