import BaseError from "./BaseError";

export default class AuthorizationError extends BaseError {
  status: number;

  constructor(detail?: string) {
    super("Web Error", detail ?? "");
    this.status = 401;
  }
}
