// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--basic-text-font-size: 16px;--basic-text-line-height: 24px}.styles_basicText__Xh53u{font-family:var(--font-main);font-style:normal;font-weight:400;font-size:16px;font-size:var(--basic-text-font-size);line-height:24px;line-height:var(--basic-text-line-height);color:var(--basic-text-color-default)}.styles_basicText__Xh53u.styles_bold__1UfhF{font-weight:700}.styles_basicText__Xh53u.styles_light__dsc34{--basic-text-color-default: var(--color-mine-shaft)}.styles_basicText__Xh53u.styles_dark__OD214{--basic-text-color-default: var(--color-soft-white)}", "",{"version":3,"sources":["webpack://./src/components/BasicText/styles.module.scss"],"names":[],"mappings":"AAAA,MACE,4BAAA,CACA,8BAAA,CAGF,yBACE,4BAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CACA,qCAAA,CACA,gBAAA,CACA,yCAAA,CACA,qCAAA,CAEA,4CACE,eAAA,CAGF,6CACE,mDAAA,CAGF,4CACE,mDAAA","sourcesContent":[":root {\n  --basic-text-font-size: 16px;\n  --basic-text-line-height: 24px;\n}\n\n.basicText {\n  font-family: var(--font-main);\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  font-size: var(--basic-text-font-size);\n  line-height: 24px;\n  line-height: var(--basic-text-line-height);\n  color: var(--basic-text-color-default);\n\n  &.bold {\n    font-weight: 700;\n  }\n\n  &.light {\n    --basic-text-color-default: var(--color-mine-shaft);\n  }\n\n  &.dark {\n    --basic-text-color-default: var(--color-soft-white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicText": "styles_basicText__Xh53u",
	"bold": "styles_bold__1UfhF",
	"light": "styles_light__dsc34",
	"dark": "styles_dark__OD214"
};
export default ___CSS_LOADER_EXPORT___;
