import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Fallback from "./components/Fallback";
import { LoginProvider } from "./contexts/login";
import { PageProvider } from "./contexts/page";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <Router>
        <Suspense fallback={<Fallback />}>
          <PageProvider>
            <LoginProvider fallback={<Fallback />}>
              <App />
            </LoginProvider>
          </PageProvider>
        </Suspense>
      </Router>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
