import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Modal from "../../components/Modal";
import UnexpectedError from "../../components/UnexpectedError";
import AccessDeniedError from "../../components/AccessDeniedError";
import Context, { initialState, PageEventHandler } from "./Context";

type Props = PropsWithChildren<unknown>;

export default function Provider({ children }: Props) {
  const [title, setTitle] = useState(initialState.title);
  const [pageTitle, setPageTitle] = useState(initialState.title);
  const [onBack, setOnBack] = useState<PageEventHandler>();
  const [onHome, setOnHome] = useState<PageEventHandler>();
  const [modals, setModals] = useState<Array<ReactNode>>([]);
  const [onFlow, setOnFlow] = useState(initialState.onFlow);

  useEffect(() => {
    if (pageTitle !== undefined) {
      document.title = `IQOS Retailer App - ${pageTitle}`;
    }
    else if (title !== undefined) {
      document.title = `IQOS Retailer App - ${title}`;
    }
    else {
      document.title = "IQOS Retailer App";
    }
  }, [title, pageTitle]);

  useEffect(() => {
    if (onFlow === true) {
      window.onbeforeunload = function () {
        return "";
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [onFlow]);

  return (
    <Context.Provider value={{
      title: title,
      setTitle: (title, pageTitle) => {
        setTitle(title);
        setPageTitle(pageTitle);
      },
      onBack: onBack,
      setOnBack: (onBack) => {
        setOnBack(() => onBack);
      },
      onHome: onHome,
      setOnHome: (onHome) => {
        setOnHome(() => onHome);
      },
      showModal: (content) => {
        setModals(modals.concat(content));
      },
      closeModal: () => {
        setModals(modals.slice(0, -1));
      },
      startFlow: () => {
        setOnFlow(true);
      },
      endFlow: () => {
        setOnFlow(false);
      },
      onFlow: onFlow,
      showUnexpectedError: () => {
        setModals(modals.concat(
          <UnexpectedError
            onDialogResult={(() => {
              setModals(modals.slice(0, -1));
            })}
          />
        ));
      },
      showAccessDeniedError: () => {
        setModals(modals.concat(
          <AccessDeniedError
            onDialogResult={(() => {
              setModals(modals.slice(0, -1));
            })}
          />
        ));
      },
    }}>
      {children}

      <TransitionGroup>
        {modals.map((content, key) => (
          <CSSTransition
            key={key}
            timeout={200}
            classNames="modal-fade"
          >
            <Modal>
              {content}
            </Modal>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Context.Provider>
  );
}