import { DependencyList, useContext, useEffect } from "react";
import Context from "./Context";
import Provider from "./Provider";

export function useOnBack(onBack: () => void, deps?: DependencyList) {
  const page = useContext(Context);

  useEffect(() => {
    page.setOnBack(onBack);

    return () => {
      page.setOnBack(undefined);
    };
  }, deps);
}

export function useFlow() {
  const page = useContext(Context);

  useEffect(() => {
    page.startFlow();

    return () => {
      page.endFlow();
    };
  }, []);
}

export { Context as PageContext, Provider as PageProvider };
