import { Content, Link, Page, VerticalAlign } from "@pmi/ui-react";
import { VoidFunctionComponent } from "react";
import { urls } from "../../App";
import logo from "../../svg/logo.svg";
import menuStyles from "../Menu/styles.module.scss";

const Fallback: VoidFunctionComponent = () => {
  return (
    <Page
      header={
        <header className={`${menuStyles.menu}`}>
          <div className={`${menuStyles.sidePanel} ${menuStyles.back}`}>
          </div>

          <div className={menuStyles.logoAndTitle}>
            <Link
              className={menuStyles.logo}
              to={urls.home}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={logo} alt="IQOS" />
            </Link>

            <span className={menuStyles.title}></span>
          </div>
          <div className={`${menuStyles.sidePanel} ${menuStyles.languages}`}>
          </div>
        </header>
      }
    >
      <Content verticalAlign={VerticalAlign.Middle}>
      </Content>
    </Page>
  );
};

export default Fallback;