import { AnchorHTMLAttributes, FunctionComponent, memo } from "react";
import styles from "./styles.module.scss";

export type SimpleLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

const SimpleLink: FunctionComponent<SimpleLinkProps> = ({ children, className, ...props }: SimpleLinkProps) => (
  <a
    className={`${styles.simpleLink} ${className ?? ""}`}
    {...props}
  >
    {children}
  </a>
);

export default memo(SimpleLink);
