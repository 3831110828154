import { ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import Radio from "../Radio";
import styles from "./styles.module.scss";

export type LabeledRadioProps = InputHTMLAttributes<HTMLInputElement>

export type LabeledRadioRef = ForwardedRef<HTMLLabelElement>;

const LabeledRadio = ({ className, children, ...props }: LabeledRadioProps, ref: LabeledRadioRef) => {
  return (
    <label
      ref={ref}
      className={`${styles.labelRadio} ${className ?? ""}`}
    >
      <Radio {...props} />
      {children}
    </label>
  );
};

export default forwardRef(LabeledRadio);
