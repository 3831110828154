import { VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons, OkDialogProps } from "../Dialog";

export type AccessDeniedErrorProps = Omit<OkDialogProps, "dialogButtons" | "title" | "text">;

const AccessDeniedError: VoidFunctionComponent<AccessDeniedErrorProps> = (props: AccessDeniedErrorProps) => {
  const [t] = useTranslation();

  return (
    <Dialog
      dialogButtons={DialogButtons.Ok}
      title={t("accessDeniedError")}
      text={t("Error.accessDeniedError")}
      {...props}
    />
  );
};

export default AccessDeniedError;