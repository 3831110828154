import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

/**
 * Namespaces of i18next.
 */
export enum I18Namespace {
  Main = "main",
  ShopAssistant = "sa",
  CallCenter = "cc",
  SalesExpert = "se",
  BusinessBuilder = "bb"
}

export enum Language {
  Georgian = "ka",
  English = "en",
}

function getLanguage() {
  const language = new URLSearchParams(window.location.search).get("language");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (language !== null && Object.values(Language).includes(language)) ? language : Language.English;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    lng: getLanguage(),
    fallbackLng: Language.English,
    debug: (process.env.NODE_ENV || "development") === "development",
    interpolation: {
      // Not needed for react as it escapes by default.
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br"],
      useSuspense: true,
    },
    defaultNS: I18Namespace.Main,
    ns: [I18Namespace.Main],
    fallbackNS: I18Namespace.Main,
    load: "currentOnly",
  });
