/**
 * User's role.
 */
export enum Role {
  ShopAssistantLight = 0,
  ShopAssistantFull = 1,
  SalesExpert = 2,
  BuisnessAdmin = 3,
  BuisnessBuilder = 4,
  CallCenter = 5,
  DarwinAgent = 6,
}

export interface AuthenticationRequest {
  username: string,
  password: string,
}

export interface City {
  id: number,
  name: string,
}

export enum Service {
  Purchase = "PURCHASE",
  Lending = "LENDING",
  Warranty = "WARRANTY",
  TradeIn = "TRADE_IN",
}

export interface AuthenticationResponse {
  id: number,
  name: string,
  token: string,
  expires: number,
  role: Role,
  city: City | null,
  services?: Array<Service>,
}

export interface RegistrationRequest {
  username: string,
  password: string,
  confirmPassword: string,
  verificationCode: string,
}

export type ResetPasswordRequest = RegistrationRequest;

export enum VerificationCodeAction {
  Registration = "REGISTRATION",
  ResetPassword = "RESET_PASSWORD",
}

export interface VerificationCodeRequest {
  username: string,
  action: VerificationCodeAction,
}

export interface VerificationCodeCheck {
  username: string,
  action: VerificationCodeAction,
  verificationCode: string,
}
