import { ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import Checkbox from "../Checkbox";
import styles from "./styles.module.scss";

export type LabeledCheckboxProps = InputHTMLAttributes<HTMLInputElement>

export type LabeledCheckboxRef = ForwardedRef<HTMLLabelElement>;

const LabeledCheckbox = ({ className, children, ...props }: LabeledCheckboxProps, ref: LabeledCheckboxRef) => {
  return (
    <label
      ref={ref}
      className={`${styles.labelCheckbox} ${className ?? ""}`}
    >
      <Checkbox {...props} />
      <div>
        {children}
      </div>
    </label>
  );
};

export default forwardRef(LabeledCheckbox);
