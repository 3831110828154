import BaseError from "./BaseError";

export default class WebError extends BaseError {
  status: number;
  code?: number;

  constructor(status: number, code?: number, message?: string) {
    super("Web Error", message ?? "");
    this.status = status;
    this.code = code;
  }
}
