import { VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Dialog, { DialogButtons, OkDialogProps } from "../Dialog";

export type UnexpectedErrorProps = Omit<OkDialogProps, "dialogButtons" | "title" | "text">;

const UnexpectedError: VoidFunctionComponent<UnexpectedErrorProps> = (props: UnexpectedErrorProps) => {
  const [t] = useTranslation();

  return (
    <Dialog
      dialogButtons={DialogButtons.Ok}
      title={t("unexpectedError")}
      text={t("Error.unexpectedError")}
      {...props}
    />
  );
};

export default UnexpectedError;