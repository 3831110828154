import { Content, Page, VerticalAlign } from "@pmi/ui-react";
import { lazy, useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Menu from "./components/Menu";
import { LoginContext } from "./contexts/login";
import Login from "./pages/Login";
import "./scss/index.scss";
import { models } from "./services/login";

const SAApp = lazy(() => import("./SA/App"));
const CCApp = lazy(() => import("./CC/App"));
const SEApp = lazy(() => import("./SE/App"));
const BBApp = lazy(() => import("./BB/App"));

const Registration = lazy(() => import("./pages/Registration"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));

export const urls = {
  forgotPassword: "/forgot-password",
  registration: "/registration",
  login: "/login",
  home: "/",
};

export default function App() {
  const location = useLocation();
  const login = useContext(LoginContext);

  return (
    <Page
      header={<Menu />}
    >
      <Content verticalAlign={!login.authenticated || [models.Role.ShopAssistantFull, models.Role.CallCenter, models.Role.SalesExpert].includes(login.role) ? VerticalAlign.Middle : VerticalAlign.Top}>
        <SwitchTransition>
          <CSSTransition
            key={login.authenticated.toString()}
            timeout={500}
            classNames="page-switch"
          >
            {(() => {
              if (login.authenticated) {
                switch (login.role) {
                  case models.Role.ShopAssistantFull:
                    return (
                      <SAApp />
                    );

                  case models.Role.CallCenter:
                    return (
                      <CCApp />
                    );

                  case models.Role.SalesExpert:
                    return (
                      <SEApp />
                    );

                  case models.Role.BuisnessBuilder:
                    return (
                      <BBApp />
                    );
                }
              }
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={location.pathname}
                    timeout={500}
                    classNames="page-switch"
                  >
                    <Routes location={location}>
                      <Route path={urls.forgotPassword} element={<ResetPassword />} />
                      <Route path={urls.registration} element={<Registration />} />
                      <Route path={urls.login} element={<Login />} />
                      <Route path="*" element={<Navigate to={urls.login} />} />
                    </Routes>
                  </CSSTransition>
                </SwitchTransition>
              );
            })()}
          </CSSTransition>
        </SwitchTransition>
      </Content>
    </Page>
  );
}