import { PropsWithChildren, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { models } from "../../services/login";
import Context, { initialState } from "./Context";

type Props = PropsWithChildren<{
  fallback: ReactChild | ReactFragment | ReactPortal,
}>;

export default function Provider(props: Props) {
  const [client] = useState(initialState.client);
  const [auth, setAuth] = useState<models.AuthenticationResponse>();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const login = async (username: string, password: string) => {
    const auth = await client.login({
      username,
      password,
    });
    localStorage.setItem("auth", JSON.stringify(auth));
    localStorage.removeItem("deviceFilter");
    setAuth(auth);
  };

  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("deviceFilter");
    setAuth(undefined);
    navigate("/");
  };

  useEffect(() => {
    (async () => {
      const auth = localStorage.getItem("auth");
      if (auth !== null) {
        const authObj = JSON.parse(auth) as models.AuthenticationResponse;
        if (Math.round(Date.now() / 1000) < authObj.expires) {
          setAuth(authObj);
        }
        else {
          logout();
        }
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Context.Provider value={auth !== undefined ? {
      client: client,
      login: login,
      logout: logout,
      authenticated: true,
      id: auth.id,
      name: auth.name,
      role: auth.role,
      token: auth.token,
      city: auth.city,
      services: auth.services,
    } : {
      client: client,
      login: login,
      logout: logout,
      authenticated: false,
    }}>
      {loading ? props.fallback : props.children}
    </Context.Provider>
  );
}
