import { createContext } from "react";
import * as login from "../../services/login";

const emptyFunction = () => {
  return;
};

type CommonProps = {
  client: login.LoginApiClient,
  login: (username: string, password: string) => Promise<void> | void,
  logout: () => void,
}

type PropsAuthenticated = {
  authenticated: true,
  id: number,
  name: string,
  role: login.models.Role,
  token: string,
  city: login.models.City | null,
  services?: Array<login.models.Service>
};

type PropsUnauthenticated = {
  authenticated: false,
};

type Props = CommonProps & (
  PropsAuthenticated | PropsUnauthenticated
);

export const initialState: Props = {
  client: new login.LoginApiClient(),
  login: emptyFunction,
  logout: emptyFunction,
  authenticated: false,
};

export default createContext<Props>(initialState);
