import { createContext, ReactNode } from "react";

const emptyFunction = () => {
  return;
};

export type PageEventHandler = () => void;

type Props = {
  title?: string,
  setTitle: (title?: string, pageTitle?: string) => void,
  onBack?: PageEventHandler,
  setOnBack: (onBack?: PageEventHandler) => void,
  onHome?: PageEventHandler,
  setOnHome: (onHome?: PageEventHandler) => void,
  showModal: (content?: ReactNode) => void,
  closeModal: () => void,
  startFlow: () => void,
  endFlow: () => void,
  onFlow: boolean,
  showUnexpectedError: () => void,
  showAccessDeniedError: () => void,
}

export const initialState: Props = {
  setTitle: emptyFunction,
  onBack: undefined,
  setOnBack: emptyFunction,
  onHome: undefined,
  setOnHome: emptyFunction,
  showModal: emptyFunction,
  closeModal: emptyFunction,
  startFlow: emptyFunction,
  endFlow: emptyFunction,
  onFlow: false,
  showUnexpectedError: emptyFunction,
  showAccessDeniedError: emptyFunction,
};

export default createContext<Props>(initialState);
