import { BorderedButton, FlexContainer, ErrorStyle, Input, HorizontalAlign, Link, Password } from "@pmi/ui-react";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { urls } from "../App";
import WithPreloader, { PreloaderState } from "../components/WithPreloader";
import { LoginContext } from "../contexts/login";
import { PageContext } from "../contexts/page";
import { I18Namespace } from "../i18n";
import { WebError } from "../services/api/v1/errors";

const initialState = {
  username: "",
  usernameError: "",
  password: "",
  passwordError: "",
  isLoading: false,
  preloaderState: PreloaderState.Hidden,
};

const Login = () => {
  const [t] = useTranslation(I18Namespace.Main);

  const navigate = useNavigate();

  const login = useContext(LoginContext);
  const page = useContext(PageContext);
  const [username, setUsername] = useState(initialState.username);
  const [usernameError, setUsernameError] = useState(initialState.usernameError);
  const [password, setPassword] = useState(initialState.password);
  const [passwordError, setPasswordError] = useState(initialState.passwordError);
  const [preloaderState, setPreloaderState] = useState(initialState.preloaderState);

  useEffect(() => {
    page.setTitle(t("authentication"));

    return () => page.setTitle();
  });

  return (
    <form>
      <WithPreloader preloaderState={preloaderState}>
        <FlexContainer width={312}>
          <Input
            name="username"
            autoComplete="username"
            label={t("login")}
            value={username}
            error={usernameError ? t(usernameError) : undefined}
            errorStyle={ErrorStyle.Floating}
            onChange={(e) => {
              setUsername(e.target.value);
              setUsernameError(initialState.usernameError);
            }}
          />
          <Password
            className="mt-36"
            name="password"
            autoComplete="current-password"
            label={t("password")}
            value={password}
            error={passwordError ? t(passwordError) : undefined}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError(initialState.usernameError);
            }}
          />
          <BorderedButton
            className="mt-24"
            disabled={username === initialState.username || password === initialState.password}
            onClick={async (e) => {
              e.preventDefault();
              setPreloaderState(PreloaderState.Visible);
              try {
                await login.login(username, password);
                navigate(urls.home);
              }
              catch (error) {
                if (error instanceof WebError) {
                  switch (error.code) {
                    case 1001:
                    case 1002:
                      setUsernameError("Error.wrongCredentials");
                      break;

                    case 1000:
                      setUsernameError("Error.requiredField");
                      setPasswordError("Error.requiredField");
                      break;

                    case 1021:
                      setUsernameError("Error.notInitialized");
                      break;
                  }
                }
                setPreloaderState(PreloaderState.Hidden);
              }
            }}>
            {t("signIn")}
          </BorderedButton>
          <Link to={urls.registration} className="mt-24">{t("signUp")}</Link>
        </FlexContainer>
      </WithPreloader>
    </form>
  );
};

export default Login;