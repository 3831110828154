import { BorderedButton, FlexContainer, Direction, Heading2 } from "@pmi/ui-react";
import { VoidFunctionComponent, DetailedHTMLProps, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

type CommonDialogProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  title?: string,
  text?: string,
}

export type YesNoDialogProps = CommonDialogProps & {
  dialogButtons: DialogButtons.YesNo,
  onDialogResult?: (result: YesNoDialogResult) => void,
  yesText?: string,
  noText?: string,
};

export type OkDialogProps = CommonDialogProps & {
  dialogButtons: DialogButtons.Ok,
  onDialogResult?: (result: OkDialogResult) => void,
  okText?: string,
};

export type OkCancelDialogProps = CommonDialogProps & {
  dialogButtons: DialogButtons.OkCancel,
  onDialogResult?: (result: OkCancelDialogResult) => void,
  okText?: string,
  cancelText?: string,
};

export type DialogProps = YesNoDialogProps | OkDialogProps | OkCancelDialogProps;

export enum YesNoDialogResult {
  Yes = 0,
  No = 1,
}

export enum OkDialogResult {
  Ok = 2,
}

export enum OkCancelDialogResult {
  Ok = 2,
  Cancel = 3,
}

export enum DialogButtons {
  YesNo,
  Ok,
  OkCancel,
}

const Dialog: VoidFunctionComponent<DialogProps> = ({ title, text, children, className, ...props }: DialogProps) => {
  const [t] = useTranslation();

  return (
    <FlexContainer className={`${styles.dialog} ${className ?? ""}`}>
      {title && (
        <Heading2>{title}</Heading2>
      )}
      {children}
      {text && (
        <p className="mt-24">
          {text}
        </p>
      )}
      <FlexContainer className={`${styles.actions} mt-36 dialog-actions`} direction={Direction.Row} gap={24}>
        {(() => {
          switch (props.dialogButtons) {
            case DialogButtons.YesNo:
              return (
                <>
                  <BorderedButton
                    className={styles.action}
                    onClick={() => {
                      if (props.onDialogResult !== undefined) {
                        props.onDialogResult(YesNoDialogResult.Yes);
                      }
                    }}
                  >
                    {props.yesText ?? t("yes")}
                  </BorderedButton>
                  <BorderedButton
                    className={styles.action}
                    onClick={() => {
                      if (props.onDialogResult !== undefined) {
                        props.onDialogResult(YesNoDialogResult.No);
                      }
                    }}
                  >
                    {props.noText ?? t("no")}
                  </BorderedButton>
                </>
              );


            case DialogButtons.Ok:
              return (
                <BorderedButton
                  className={styles.action}
                  onClick={() => {
                    if (props.onDialogResult !== undefined) {
                      props.onDialogResult(OkDialogResult.Ok);
                    }
                  }}
                >
                  {props.okText ?? t("ok")}
                </BorderedButton>
              );

            case DialogButtons.OkCancel:
              return (
                <>
                  <BorderedButton
                    className={styles.action}
                    onClick={() => {
                      if (props.onDialogResult !== undefined) {
                        props.onDialogResult(OkCancelDialogResult.Ok);
                      }
                    }}
                  >
                    {props.okText ?? t("ok")}
                  </BorderedButton>
                  <BorderedButton
                    className={styles.action}
                    onClick={() => {
                      if (props.onDialogResult !== undefined) {
                        props.onDialogResult(OkCancelDialogResult.Cancel);
                      }
                    }}
                  >
                    {props.cancelText ?? t("cancel")}
                  </BorderedButton>
                </>
              );
          }
        })()}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Dialog;