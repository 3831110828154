import CachedResponse from "./CachedResponse";
import { NetworkError, NotFoundWebError, PermissionDeniedWebError, WebError } from "./errors";
import AuthorizationError from "./errors/AuthorizationError";
import { ExpectedError } from "./models";

type Method = "GET" | "POST" | "PUT" | "DELETE";

export default class Client {
  public readonly token?: string;

  constructor(token?: string) {
    this.token = token;
  }

  protected async fetch<T>(method: Method, url: string, body?: object | FormData, suppressCache?: boolean): Promise<T> {
    const cachedResponse = new CachedResponse<T>(url);
    const headers: HeadersInit = {
      "Accept": "application/json",
    };
    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }
    if (this.token !== undefined) {
      headers["Authorization"] = `Token ${this.token}`;
    }
    if (suppressCache !== undefined && !suppressCache) {
      if (cachedResponse.load()) {
        // Be careful, we store response headers lowercase.
        // Not all web servers send headers capitalized.
        if (cachedResponse.headers["last-modified"] !== undefined) {
          headers["If-Modified-Since"] = cachedResponse.headers["last-modified"];
        }
      }
    }
    try {
      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: body === undefined ? undefined : (body instanceof FormData ? body : JSON.stringify(body)),
      });
      let responseBody: T | ExpectedError | undefined;
      try {
        responseBody = await response.json();
      }
      catch (error) {
        // Do nothing.
      }
      switch (response.status) {
        case 200:
        case 201:
        case 202:
        case 203:
        case 204:
          if (suppressCache !== undefined) {
            cachedResponse.save(Object.fromEntries(response.headers.entries()), responseBody as T);
          }
          return responseBody as T;

        case 304:
          return cachedResponse.body;

        case 401:
          throw new AuthorizationError((responseBody as any).detail);

        case 403:
          throw new PermissionDeniedWebError((responseBody as ExpectedError).error_code, (responseBody as ExpectedError).error_message);

        case 404:
          throw new NotFoundWebError((responseBody as ExpectedError).error_code, (responseBody as ExpectedError).error_message);

        default:
          throw new WebError(response.status, (responseBody as ExpectedError).error_code, (responseBody as ExpectedError).error_message);
      }
    }
    catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
      if (error instanceof NotFoundWebError || error instanceof WebError || error instanceof AuthorizationError) {
        throw error;
      }
      throw new NetworkError();
    }
  }
}
