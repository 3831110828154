// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--content-padding: 0 36px 36px 32px}.styles_content__PnyPw{flex-grow:3;width:100%;box-sizing:border-box;padding:0 36px 36px 32px;padding:var(--content-padding)}", "",{"version":3,"sources":["webpack://./src/components/Content/styles.module.scss"],"names":[],"mappings":"AAAA,MACE,mCAAA,CAGF,uBACE,WAAA,CACA,UAAA,CACA,qBAAA,CACA,wBAAA,CACA,8BAAA","sourcesContent":[":root {\n  --content-padding: 0 36px 36px 32px;\n}\n\n.content {\n  flex-grow: 3;\n  width: 100%;\n  box-sizing: border-box;\n  padding: 0 36px 36px 32px;\n  padding: var(--content-padding);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "styles_content__PnyPw"
};
export default ___CSS_LOADER_EXPORT___;
