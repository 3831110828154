import { BaseApiClient } from "../api/v1";
import { AuthenticationRequest, AuthenticationResponse, RegistrationRequest, ResetPasswordRequest, VerificationCodeCheck, VerificationCodeRequest } from "./models";

export default class Client extends BaseApiClient {
  public async login(request: AuthenticationRequest): Promise<AuthenticationResponse> {
    return this.fetch("POST", "/api/v1/users/token", request);
  }

  public async sendVerificationCode(request: VerificationCodeRequest): Promise<void> {
    return this.fetch("POST", "/api/v1/users/verification-code/send", request);
  }

  public async checkVerificationCode(request: VerificationCodeCheck): Promise<void> {
    return this.fetch("POST", "/api/v1/users/verification-code/check", request);
  }

  public async register(request: RegistrationRequest): Promise<void> {
    return this.fetch("POST", "/api/v1/users/register", request);
  }

  public async resetPassword(request: ResetPasswordRequest): Promise<void> {
    return this.fetch("POST", "/api/v1/users/reset-password", request);
  }
}