import { ChangeEventHandler, FunctionComponent, HTMLAttributes, useEffect, useRef, useState } from "react";
import InputError, { ErrorStyle } from "../InputError";
import styles from "./styles.module.scss";

export type DashedTextboxHTMLDivElement = HTMLDivElement & {
  value: string,
};

export type DashedTextboxProps = Omit<HTMLAttributes<HTMLDivElement>, "onChange"> & {
  length: number,
  error?: string,
  onChange?: ChangeEventHandler<DashedTextboxHTMLDivElement>,
  errorStyle?: ErrorStyle,
  value?: string,
  defaultValue?: string,
  disabled?: boolean,
};

const emptyValue = "";

const valueToValues = (value: string, length: number) => value.replace(/[^\d]/, emptyValue).split(emptyValue).slice(0, length);

const valuesToValue = (values: Array<string>, length: number) => values.join(emptyValue).slice(0, length);

const DashedTextbox: FunctionComponent<DashedTextboxProps> = ({ className, disabled, length, error, errorStyle, value, defaultValue, onChange, ...props }: DashedTextboxProps) => {
  const div = useRef<HTMLDivElement>(null);
  const inputs: Array<HTMLInputElement> = [];
  const [values, setValues] = useState(
    (value !== undefined) ?
      valueToValues(value, length).concat(Array(Math.max(0, length - value.length)).fill(emptyValue)) :
      (defaultValue !== undefined) ?
        valueToValues(defaultValue, length).concat(Array(Math.max(0, length - defaultValue.length)).fill(emptyValue)) :
        Array(length).fill(emptyValue)
  );

  useEffect(() => {
    if (value !== undefined) {
      setValues(valueToValues(value, length).concat(Array(Math.max(0, length - value.length)).fill(emptyValue)));
    }
  }, [value]);

  return (
    <div
      ref={div}
      className={`${styles.dashedTextbox} ${className ?? emptyValue}`}
      {...props}
    >
      <div className={styles.inputs}>
        {Array(length).fill(emptyValue).map((_digit, index) => (
          <input
            key={index}
            inputMode="numeric"
            className={styles.input}
            placeholder="X"
            ref={(ref: HTMLInputElement) => inputs[index] = ref}
            disabled={disabled}
            value={values[index] ?? emptyValue}
            onKeyDown={(e) => {
              const oldValue = valuesToValue(values, length);
              let newValues = values.map((v) => v);
              switch (e.key) {
                case "Backspace":
                  values.splice(index, 1);
                  newValues = values.map((d) => d);
                  if (value === undefined) {
                    setValues(newValues);
                  }
                  inputs[Math.max(index - 1, 0)].focus();
                  break;

                case "Delete":
                  values.splice(index, 1);
                  newValues = values.slice(0, length).map((d) => d);
                  if (value === undefined) {
                    setValues(newValues);
                  }
                  break;

                case "ArrowLeft":
                  inputs[Math.max(0, index - 1)].focus();
                  break;

                case "ArrowRight":
                  inputs[Math.min(length - 1, index + 1)].focus();
                  break;
              }

              const newValue = valuesToValue(newValues, length);
              if (div.current !== null && onChange !== undefined && newValue !== oldValue) {
                onChange(Object.assign(e, {
                  target: Object.assign(div.current, {
                    value: newValue,
                  }),
                }));
              }
            }}
            onFocus={() => {
              if (index > 0) {
                if (values[index - 1] === undefined || values[index - 1] === emptyValue) {
                  inputs[index - 1].focus();
                }
              }
            }}
            onPaste={(e) => {
              const oldValue = valuesToValue(values, length);
              const pastedValue = valueToValues(e.clipboardData.getData("text"), length);
              values.splice(index, 0, ...pastedValue);
              const newValues = values.slice(0, length);
              setValues(newValues);

              const newValue = valuesToValue(newValues, length);
              if (div.current !== null && onChange !== undefined && newValue !== oldValue) {
                onChange(Object.assign(e, {
                  target: Object.assign(div.current, {
                    value: newValue,
                  }),
                }));
              }
              inputs[Math.min(length - 1, index + pastedValue.length)].focus();
              e.preventDefault();
            }}
            onInput = {(e) => {
              const oldValue = valuesToValue(values, length);
              let newValues = values.map((v) => v);
              const targetValue = (e.nativeEvent as InputEvent).data;

              switch (targetValue) {
                case "1":
                case "2":
                case "3":
                case "4":
                case "5":
                case "6":
                case "7":
                case "8":
                case "9":
                case "0":
                  // Prevents a bug with repeating of last character.
                  // Do not splice 'values' array if state won't update.
                  if (values.length >= length && targetValue === values[length - 1]) {
                    return;
                  }
                  // Be careful. It is a dirty way to fill 'values' constant before state update.
                  // It is needed for 'onFocus' work in a right way.
                  values.splice(index, 0, targetValue);
                  newValues = values.slice(0, length);
                  if (value === undefined) {

                    setValues(newValues);
                  }
                  inputs[Math.min(length - 1, index + 1)].focus();
                  break;
              }

              const newValue = valuesToValue(newValues, length);
              if (div.current !== null && onChange !== undefined && newValue !== oldValue) {
                onChange(Object.assign(e, {
                  target: Object.assign(div.current, {
                    value: newValue,
                  }),
                }));
              }

              e.preventDefault();
            }}
          />
        ))}
      </div>
      <div className={styles.errorContainer}>
        <InputError className={styles.error} errorStyle={errorStyle}>{error}</InputError>
      </div>
    </div>
  );
};

export default DashedTextbox;